@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body{
  height: 100vh;
  background-color: #202124;
}

#root{
  height: 100vh;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.editorContainer{
  flex: 1;
}

.warning-popover {
  padding: 10px;
  max-height: 250px;
  overflow-y: auto;
}